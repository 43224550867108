import { useSelector } from 'react-redux';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { AppState } from '../index';

export function useBlockNumber(): number | undefined {
  const { chainId } = useActiveWeb3React();

  return useSelector((state: AppState) => state.application.blockNumber[chainId ?? -1]);
}

export default useBlockNumber;
