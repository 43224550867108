import React from 'react';
import { SvgProps } from './types';

const Icon: React.FC<SvgProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="27px" height="27px">
      <path
        fillRule="evenodd"
        stroke="rgb(39, 46, 50)"
        strokeWidth="2px"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        fill="rgb(18, 22, 26)"
        d="M7.000,3.000 L19.000,3.000 C21.209,3.000 23.000,4.791 23.000,7.000 L23.000,19.000 C23.000,21.209 21.209,23.000 19.000,23.000 L7.000,23.000 C4.791,23.000 3.000,21.209 3.000,19.000 L3.000,7.000 C3.000,4.791 4.791,3.000 7.000,3.000 Z"
      />
      <path
        fillRule="evenodd"
        fill="rgb(0, 219, 222)"
        d="M18.837,16.007 L16.795,18.570 C16.473,19.101 15.851,19.101 15.528,18.570 L13.487,16.007 C13.072,15.323 13.449,14.320 14.121,14.320 L15.029,14.320 L15.029,9.456 C15.029,8.646 15.536,7.990 16.162,7.990 C16.788,7.990 17.295,8.646 17.295,9.456 L17.295,14.320 L18.202,14.320 C18.875,14.320 19.252,15.323 18.837,16.007 ZM11.882,12.638 L10.975,12.638 L10.975,17.502 C10.975,18.312 10.468,18.969 9.841,18.969 C9.216,18.969 8.708,18.312 8.708,17.502 L8.708,12.638 L7.801,12.638 C7.128,12.638 6.752,11.635 7.167,10.951 L9.208,8.389 C9.531,7.857 10.153,7.857 10.475,8.389 L12.516,10.951 C12.932,11.635 12.555,12.638 11.882,12.638 Z"
      />
    </svg>
  );
};

export default Icon;
