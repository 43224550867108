import React from 'react';
import { SvgProps } from './types';

const Icon: React.FC<SvgProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16px" height="16px">
      <path
        fillRule="evenodd"
        d="M15.391,7.438 L9.399,1.510 C9.033,1.146 8.526,0.940 7.996,0.940 C7.466,0.940 6.959,1.146 6.592,1.510 L0.603,7.438 C-0.015,8.058 0.064,8.580 0.165,8.800 C0.343,9.182 0.754,9.418 1.193,9.388 L2.071,9.388 L2.071,13.948 C2.071,14.983 2.853,15.962 3.965,15.962 L6.514,15.962 L6.514,11.132 C6.514,10.615 6.433,10.327 6.989,10.327 L9.004,10.327 C9.561,10.327 9.477,10.615 9.477,11.132 L9.477,15.959 L12.029,15.959 C13.139,15.959 13.922,14.983 13.922,13.945 L13.922,9.388 L14.799,9.388 C15.238,9.418 15.649,9.182 15.827,8.800 C15.929,8.580 16.009,8.050 15.391,7.438 L15.391,7.438 Z"
      />
    </svg>
  );
};

export default Icon;
