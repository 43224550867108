import React from 'react';
import { SvgProps } from './types';

const Icon: React.FC<SvgProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16px" height="14px">
      <path
        fillRule="evenodd"
        d="M15.735,3.783 L13.419,0.580 C13.156,0.216 12.734,-0.000 12.284,0.000 L3.707,0.000 C3.262,0.000 2.844,0.212 2.580,0.570 L0.273,3.700 C0.096,3.940 0.000,4.232 0.000,4.530 L0.000,12.000 C0.000,12.530 0.211,13.039 0.586,13.414 C0.961,13.789 1.470,14.000 2.000,14.000 L14.000,14.000 C14.531,14.000 15.039,13.789 15.414,13.414 C15.789,13.039 16.000,12.530 16.000,12.000 L16.000,4.603 C16.000,4.309 15.907,4.022 15.735,3.783 ZM2.310,3.993 C2.249,3.976 2.200,3.931 2.177,3.872 C2.155,3.813 2.161,3.747 2.195,3.693 L3.505,1.626 L3.555,1.562 C3.631,1.484 3.735,1.440 3.843,1.440 L6.873,1.440 C7.094,1.440 7.273,1.619 7.273,1.840 L7.273,3.600 L7.265,3.680 C7.227,3.866 7.063,4.000 6.873,4.000 L2.363,4.000 L2.310,3.993 ZM10.920,11.568 C10.814,11.753 10.616,11.867 10.403,11.867 L5.597,11.867 C5.267,11.866 5.000,11.599 5.000,11.269 C5.000,10.940 5.267,10.673 5.597,10.673 L10.403,10.673 C10.616,10.673 10.814,10.786 10.920,10.971 C11.027,11.156 11.027,11.383 10.920,11.568 ZM13.637,4.000 L9.225,4.000 C9.005,4.000 8.825,3.820 8.825,3.600 L8.825,1.840 C8.825,1.619 9.004,1.440 9.225,1.440 L12.158,1.440 C12.295,1.440 12.422,1.511 12.495,1.626 L13.805,3.693 C13.844,3.755 13.846,3.832 13.811,3.896 C13.777,3.960 13.710,4.000 13.637,4.000 Z"
      />
    </svg>
  );
};

export default Icon;
