import React from 'react';
import { SvgProps } from './types';

const Icon: React.FC<SvgProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16px" height="16px">
      <path
        fillRule="evenodd"
        d="M11.000,0.000 L5.000,0.000 C2.239,0.000 0.000,2.239 0.000,5.000 L0.000,11.000 C0.000,13.761 2.239,16.000 5.000,16.000 L11.000,16.000 C13.761,16.000 16.000,13.761 16.000,11.000 L16.000,5.000 C16.000,2.239 13.761,0.000 11.000,0.000 ZM6.820,7.324 L6.820,11.236 C6.820,11.887 6.399,12.415 5.880,12.415 C5.361,12.415 4.941,11.887 4.941,11.236 L4.941,7.324 L4.189,7.324 C3.631,7.324 3.319,6.517 3.663,5.966 L5.355,3.905 C5.622,3.478 6.138,3.478 6.406,3.905 L8.098,5.966 C8.442,6.517 8.129,7.324 7.572,7.324 L6.820,7.324 ZM12.337,10.034 L10.646,12.095 C10.378,12.522 9.862,12.522 9.595,12.095 L7.903,10.034 C7.558,9.483 7.871,8.676 8.428,8.676 L9.180,8.676 L9.180,4.764 C9.180,4.113 9.601,3.585 10.120,3.585 C10.639,3.585 11.060,4.113 11.060,4.764 L11.060,8.676 L11.812,8.676 C12.369,8.676 12.682,9.483 12.337,10.034 Z"
      />
    </svg>
  );
};

export default Icon;
