import React from 'react';
import { SvgProps } from './types';

const Icon: React.FC<SvgProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18px" height="15px">
      <path
        fillRule="evenodd"
        d="M16.700,9.073 L16.700,5.536 C16.700,4.702 16.024,4.019 15.198,4.019 L14.447,4.019 L14.447,1.972 L15.498,1.441 C15.874,1.289 16.024,0.834 15.799,0.455 C15.648,0.075 15.198,-0.076 14.822,0.151 L13.321,0.910 C13.096,0.986 12.946,1.213 12.946,1.516 L12.946,4.019 L10.318,4.019 L8.817,0.834 C8.591,0.303 8.065,-0.000 7.465,-0.000 L3.186,-0.000 C2.360,-0.000 1.684,0.682 1.684,1.516 L1.684,8.525 C1.223,9.200 0.934,9.997 0.934,10.846 C0.934,13.045 2.735,14.865 4.912,14.865 C7.090,14.865 8.891,13.045 8.891,10.846 C8.891,10.781 8.889,10.699 8.884,10.618 L11.625,10.618 C11.511,10.949 11.444,11.285 11.444,11.680 C11.444,13.500 12.871,14.941 14.673,14.941 C16.474,14.941 17.901,13.500 17.901,11.680 C17.971,10.614 17.445,9.685 16.700,9.073 ZM4.912,13.424 C3.561,13.424 2.435,12.287 2.435,10.921 C2.435,9.480 3.561,8.343 4.912,8.343 C6.264,8.343 7.390,9.480 7.390,10.846 C7.465,12.287 6.339,13.424 4.912,13.424 ZM3.110,3.620 L3.110,2.115 C3.110,1.784 3.376,1.516 3.704,1.516 L7.014,1.516 C7.244,1.516 7.452,1.650 7.550,1.860 L8.253,3.365 C8.438,3.762 8.151,4.219 7.716,4.219 L3.704,4.219 C3.376,4.219 3.110,3.951 3.110,3.620 ZM14.673,13.424 C13.696,13.424 12.946,12.666 12.946,11.680 C12.946,10.694 13.696,9.936 14.673,9.936 C15.648,9.936 16.399,10.694 16.399,11.680 C16.399,12.666 15.648,13.424 14.673,13.424 Z"
      />
    </svg>
  );
};

export default Icon;
